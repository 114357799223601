import * as React from "react"

const IconMail = React.forwardRef((props, ref) => (
  <svg
    viewBox="0 0 512 512"
    className="iconMail"
    ref={ref}
  >
    <path d="M501.801,139.942H171.312c-5.633,0-10.199,4.566-10.199,10.199v19.235h-97.21c-5.633,0-10.199,4.566-10.199,10.199
                        c0,5.633,4.566,10.199,10.199,10.199h97.21v132.452h-58.101c-5.633,0-10.199,4.566-10.199,10.199s4.566,10.199,10.199,10.199
                        h58.101v19.235c0,5.633,4.566,10.199,10.199,10.199h330.489c5.633,0,10.199-4.566,10.199-10.199V150.141
                        C512,144.508,507.434,139.942,501.801,139.942z M476.641,160.34l-140.07,114.985L197.14,160.34H476.641z M491.602,351.661h-310.09
                        v-177.77l148.556,122.51c1.884,1.553,4.186,2.331,6.489,2.331c2.295,0,4.591-0.772,6.471-2.316L491.602,174.45V351.661z"/>
    <path d="M119.673,244.768H10.199C4.566,244.768,0,249.334,0,254.967c0,5.633,4.566,10.199,10.199,10.199h109.474
                        c5.633,0,10.199-4.566,10.199-10.199C129.873,249.334,125.306,244.768,119.673,244.768z"/>
    <path d="M69.355,322.227h-5.451c-5.633,0-10.199,4.566-10.199,10.199c0,5.633,4.566,10.199,10.199,10.199h5.451
                        c5.633,0,10.199-4.566,10.199-10.199C79.554,326.793,74.988,322.227,69.355,322.227z"/>
  </svg>
))

const IconOk = React.forwardRef((props, ref) => (
  <svg className="iconOk" ref={ref} viewBox="0 0 24 24"><path clipRule="evenodd" d="M21.652,3.211c-0.293-0.295-0.77-0.295-1.061,0L9.41,14.34  c-0.293,0.297-0.771,0.297-1.062,0L3.449,9.351C3.304,9.203,3.114,9.13,2.923,9.129C2.73,9.128,2.534,9.201,2.387,9.351  l-2.165,1.946C0.078,11.445,0,11.63,0,11.823c0,0.194,0.078,0.397,0.223,0.544l4.94,5.184c0.292,0.296,0.771,0.776,1.062,1.07  l2.124,2.141c0.292,0.293,0.769,0.293,1.062,0l14.366-14.34c0.293-0.294,0.293-0.777,0-1.071L21.652,3.211z" fillRule="evenodd" /></svg>
))

const IconAlert = React.forwardRef((props, ref) => (
  <svg className="iconAlert" ref={ref} viewBox="0 0 466.705 466.705" >
    <path d="M459.925,358.907L269.505,61.503c-7.893-12.323-21.518-19.776-36.145-19.776c-14.628,0-28.254,7.453-36.146,19.776
	L6.78,358.907c-8.462,13.209-9.047,29.987-1.511,43.752c7.522,13.757,21.964,22.319,37.655,22.319h380.854
	c15.691,0,30.134-8.555,37.656-22.319C468.972,388.894,468.387,372.116,459.925,358.907z M209.453,162.607
	c0-13.078,10.605-23.675,23.675-23.675c13.072,0,23.676,10.597,23.676,23.675v101.584c0,13.078-10.604,23.675-23.676,23.675
	c-13.07,0-23.675-10.597-23.675-23.675V162.607z M232.682,373.613c-16.338,0-29.594-13.249-29.594-29.594
	c0-16.347,13.256-29.594,29.594-29.594c16.339,0,29.595,13.247,29.595,29.594C262.276,360.364,249.021,373.613,232.682,373.613z"/>
  </svg>
))

export default IconMail
export { IconOk, IconAlert }