import React from "react"
import { graphql } from "gatsby"
import ExcerptAndContent from "../components/excerpt-and-content"
import Form from "../components/form"
import SEO from "../components/seo"

export default function Template({
  data,
}) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  const contentLabels = {
    intro: frontmatter.label_intro,
    signature: frontmatter.label_signature,
  }

  const formLabels = {
    firstname: frontmatter.field_firstname,
    lastname: frontmatter.field_lastname,
    email: frontmatter.field_email,
    emailHelper: frontmatter.field_email_helper,
    emailSent: frontmatter.field_email_sent,
    emailError: frontmatter.field_email_error,
    message: frontmatter.field_message,
    phone: frontmatter.field_phone,
    submit: frontmatter.button_submit,
    contactus: frontmatter.label_contactus,
  }

  const [excerpt, content] = html.split('<p>øøø</p>')

  return (
    <>
      <SEO title="Siam Capital" description={contentLabels.intro}></SEO>
      <div className="contentGrid">
        <ExcerptAndContent excerpt={excerpt} content={content} labels={contentLabels} />
        <Form labels={formLabels} />
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        button_submit
        field_firstname
        field_lastname
        field_email
        field_email_helper
        field_email_sent
        field_email_error
        field_message
        field_phone
        label_contactus
        label_intro
        label_signature
        path
        title
        locale
      }
    }
  }
`