import React, { useEffect, useRef, useReducer, useState } from "react"
import TextField from "@material-ui/core/TextField"
import axios from "axios"
import gsap from "gsap"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import IconMail, { IconOk, IconAlert } from "./icons"
gsap.registerPlugin(ScrollToPlugin);

export default function ExcerptAndContent({ labels }) {
  const containerRef = useRef()
  const fieldRef = useRef()
  const submitRef = useRef()
  const iconMailRef = useRef()
  const formMaskRef = useRef()
  const submitAnimation = useRef(gsap.timeline())
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [emailInError, setEmailInError] = useState(false)
  const [sendingStatus, setSendingStatus] = useState(null)

  const initialState = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
    captcha: "",
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    firstname,
    lastname,
    email,
    emailHelper,
    emailSent,
    emailError,
    phone,
    message,
    submit,
    contactus,
  } = labels

  function reducer(state, action) {
    switch (action.type) {
      case "set":
        return Object.assign({}, { ...state, [action.field]: action.value })
      case "reset":
        return Object.assign({}, initialState)
      default:
        console.error(`Field ${state.fieldName} not recognized`)
    }
  }

  function setFieldValue(fieldName, e) {
    if (!e.target) return;
    const val = e.target.value;
    switch (fieldName) {
      case "email":
        dispatch({ type: 'set', field: 'email', value: val })
        if (/^.+@.+\..+$/.test(val)) {
          setEmailInError(false)
        } else {
          setEmailInError(val.length > 0 ? true : false)
        }
        break;
      default:
        dispatch({ type: 'set', field: fieldName, value: val })
    }
    if (state.lastname && state.firstname && (state.email || state.phone) && state.message) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }

  function animationFormSubmit(callback) {
    const tl = submitAnimation.current;
    const label = submitRef.current.querySelector('label');
    tl.to(submitRef.current, 0.3, { width: 90 });
    tl.to(label, 0.3, { color: '#f23030', autoAlpha: 0, display: 'none' }, '<')
    tl.to(iconMailRef.current, 0.3, { autoAlpha: 1, display: 'block' })
    const tl2 = gsap.timeline({ repeat: -1, yoyo: 1 }, '<')
    tl2.to(iconMailRef.current, 0.3, { x: -5, ease: 'back.out' })
    tl.add(tl2)
    tl.call(callback, null, "+=0.5")
  }

  function animationEmailSent() {
    submitAnimation.current.pause();
    const tl = gsap.timeline()
    gsap.set(formMaskRef.current, { display: 'flex', height: 0, autoAlpha: 0.9 })
    tl.to(formMaskRef.current, 0.6, { height: '100%' })
    tl.to(window, { duration: 0.6, scrollTo: { y: formMaskRef.current.querySelector('.sentMessage'), offsetY: 200 } }, "<")
    if (sendingStatus === 'ok') {
      dispatch({ type: 'reset' })
    }
  }

  function submitForm(e) {
    e.preventDefault()
    if (submitDisabled || emailInError) return
    animationFormSubmit(() => {
      axios.post('/xke0902ks.php', state).then(response => {
        setSendingStatus('ok')
        animationEmailSent()
      }, error => {
        setSendingStatus('error')
        animationEmailSent()
      })
    });
  }

  useEffect(() => {
    const handle = () => {
      fieldRef.current.querySelector("textarea").style.minHeight = "auto"
      const height = containerRef.current.getBoundingClientRect().height
      fieldRef.current.querySelector("textarea").style.minHeight = `${
        height - 298
        }px`
    }
    setTimeout(function () {
      handle()
    }, 50)
    window.addEventListener("resize", handle)
    return () => {
      window.removeEventListener("resize", handle)
    }
  }, [])

  return (
    <div ref={containerRef} className="formContainer">
      <div className="caption">{contactus}</div>
      <form noValidate autoComplete="off">
        <TextField
          className="lastname"
          label={lastname}
          fullWidth
          onChange={e => setFieldValue("lastname", e)}
          value={state.lastname}
        />
        <TextField
          className="firstname"
          label={firstname}
          onChange={e => setFieldValue("firstname", e)}
          fullWidth
          value={state.firstname}
        />
        <TextField
          className="email"
          label={email}
          type="email"
          onChange={e => setFieldValue("email", e)}
          value={state.email}
          helperText={emailInError ? emailHelper : ''}
          error={emailInError}
          fullWidth
        />
        <TextField
          className="phone"
          label={phone}
          onChange={e => setFieldValue("phone", e)}
          value={state.phone}
          fullWidth
        />
        <TextField
          className="message"
          ref={fieldRef}
          label={message}
          value={state.message}
          multiline
          fullWidth
          onChange={e => setFieldValue("message", e)}
        />
        <TextField
          tabIndex="-1"
          className="captcha"
          label="captcha"
          onChange={e => setFieldValue("captcha", e)}
          fullWidth
        />
        <div className="buttonContainer">
          <button ref={submitRef} disabled={submitDisabled || emailInError} onClick={submitForm}>
            <label>{submit}</label>
            <IconMail ref={iconMailRef} />
          </button>
        </div>
      </form>
      <div className="formMask" ref={formMaskRef}>
        <div className="sentMessage">{sendingStatus === 'ok' ? emailSent : emailError}</div>
        {sendingStatus === 'ok' ? <IconOk /> : <IconAlert />}
      </div>
    </div>
  )
}
