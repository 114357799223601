import React, { useRef, useState } from "react"
import gsap from "gsap"
import ScrollToPlugin from "gsap/ScrollToPlugin"
gsap.registerPlugin(ScrollToPlugin);


export default function ExcerptAndContent({ excerpt, content, labels }) {

  const ref = useRef()
  const toggleRef = useRef()
  const [visible, setVisible] = useState(false)
  const [toggle, setToggle] = useState("+")

  const { intro, signature } = labels;

  const handleClick = () => {
    const tl = gsap.timeline()
    if (visible) {
      tl.to(ref.current, 0.3, { height: 0, autoAlpha: 0 })
    } else {
      tl.to(ref.current, 0.3, { height: "auto", autoAlpha: 1 })
    }
    setToggle(toggle === "+" ? "−" : "+")
    if (!visible) {
      tl.to(window, {duration: 0.5, scrollTo: {y: toggleRef.current, offsetY: 0}}, "<0.3")
    }
    setVisible(!visible)
  }

  return (
    <div className="contentContainer">
      <h1>{intro}</h1>
      <div
        className="excerpt"
        dangerouslySetInnerHTML={{ __html: excerpt }}
      ></div>
      <button ref={toggleRef} className="toggle" onClick={handleClick}>
        {toggle}
      </button>
      <div
        ref={ref}
        className="content"
      >
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
        <div className="signature"><span>Julien-Pierre Pastor</span>, {signature}</div>
      </div>
    </div>
  )
}
